import Vue from "vue";
import Vuex from "vuex";

import video from "./modules/video";
import project from "./modules/project";
import pdf from "./modules/pdf";
Vue.use(Vuex);

const modules = {
  video,
  project,
  pdf,
};

// 定义store
export default new Vuex.Store({
  modules,
});
