import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 解决冗余导航
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/project",
    name: "Project",
    component: () => import("../views/Project.vue"),
  },

  {
    path: "/searchResult",
    name: "searchResult",
    component: () => import("../views/SearchResult.vue"),
  },
  {
    path: "/project-detail",
    name: "ProjectDetail",
    component: () => import("../views/Project-detail.vue"),
  },

  {
    path: "/video",
    name: "Vidoe",
    component: () => import("../views/Video-list.vue"),
  },
  {
    path: "/video-detail",
    name: "VidoeDetail",
    component: () => import("../views/Video-detail.vue"),
  },
  {
    path: "/pdf",
    name: "Pdf",
    component: () => import("../views/pdf-list.vue"),
  },
  {
    path: "/pdf-detail",
    name: "PdfDetail",
    component: () => import("../views/pdf-detail.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
  },
];

const router = new VueRouter({
  // mode:"history",
  routes,
});

export default router;
