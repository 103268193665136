//pdf模块
export default {
  namespaced: true,
  state() {
    return {
      titleIndex: 0 /** 选中的标题索引值 */,
      titleId: "" /** 选中的标题id值 */,
    };
  },
  mutations: {
    /** 设置选中索引项 */
    setTitleIndex(state, payload) {
      state.titleIndex = payload;
    },
    /** 设置选中id项 */
    setTitleId(state, payload) {
      state.titleId = payload;
    },
  },
  actions: {},
};
