// 项目模块
export default {
  namespaced: true,
  state() {
    return {
      /** 选中的数据 */
      selectedData: {
        country: "",
        type: "",
        year: "",
      },
    };
  },
  mutations: {
    /** 设置选中项 */
    setSelected(state, data) {
      state.selectedData[data.key] = data.value;
    },
  },
  actions: {},
};
