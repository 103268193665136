<template>
  <div id="app" @dblclick="toggle">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    // window.addEventListener("dblclick", () => {
    //   alert("mounted");
    //   const element = document.documentElement; //获取整个页面元素
    //   if (document.fullscreenElement) {
    //     // 判断是否处于全屏状态
    //     if (document.exitFullscreen) {
    //       document.exitFullscreen();
    //     } else if (element.mozCancelFullScreen) {
    //       /* Firefox */
    //       document.mozCancelFullScreen();
    //     } else if (document.webkitExitFullscreen) {
    //       /* Chrome, Safari and Opera */
    //       document.webkitExitFullscreen();
    //     } else if (document.msExitFullscreen) {
    //       /* IE/Edge */
    //       document.msExitFullscreen();
    //     }
    //   } else {
    //     if (element.requestFullscreen) {
    //       element.requestFullscreen();
    //     } else if (element.mozRequestFullScreen) {
    //       /* Firefox */
    //       element.mozRequestFullScreen();
    //     } else if (element.webkitRequestFullscreen) {
    //       /* Chrome, Safari & Opera */
    //       element.webkitRequestFullscreen();
    //     } else if (element.msRequestFullscreen) {
    //       /* IE/Edge */
    //       element.msRequestFullscreen();
    //     }
    //   }
    // });
  },
  computed: {},
  methods: {
    toggle() {
      const element = document.documentElement; //获取整个页面元素
      // alert(document.fullscreenElement);
      if (document.fullscreenElement) {
        // 判断是否处于全屏状态
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (element.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          /* Firefox */
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          /* IE/Edge */
          element.msRequestFullscreen();
        }
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss">
@import "./assets/scss/_fun.scss";
#id {
  overscroll-behavior: none;
}
</style>
