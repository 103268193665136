import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import "./assets/scss/reset.scss";
import "./assets/scss/_fun.scss";

import { Message } from "element-ui";
Vue.prototype.$message = Message;
Vue.config.productionTip = false;

Vue.component("simple-bar", simplebar);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
